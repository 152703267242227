import React, { useState } from "react";
import { EntitiesApp } from "./entities/entitiesApp";
import { ListEntitiesInitial } from "./listEntitiesInitial";

export const ListEntitiesApp = () => {
	const [option, setOption] = useState(null);

	const changeButton = (option) => {
		setOption(option);
	};
	return (
		<div>
			<article className="titulo9">Configuración listas de entidades</article>
			<div className="containerTime11">
				<div className="containerTime12">
					{option === "ARL" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("ARL")}
						>
							ARL
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("ARL")}
						>
							ARL
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "EPS" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("EPS")}
						>
							EPS
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("EPS")}
						>
							EPS
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "PENSIONES" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("PENSIONES")}
						>
							PENSIONES
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("PENSIONES")}
						>
							PENSIONES
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "CESANTIAS" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("CESANTIAS")}
						>
							CESANTIAS
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("CESANTIAS")}
						>
							CESANTIAS
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "CAJA" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("CAJA")}
						>
							CAJA
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("CAJA")}
						>
							CAJA
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "CARGOS" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("CARGOS")}
						>
							CARGOS
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("CARGOS")}
						>
							CARGOS
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "TIPO ID" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("TIPO ID")}
						>
							Tipo ID
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("TIPO ID")}
						>
							Tipo ID
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "TIPO CONTRATO" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("TIPO CONTRATO")}
						>
							Tipo Contrato
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("TIPO CONTRATO")}
						>
							Tipo Contrato
						</button>
					)}
				</div>
				<div className="containerTime12">
					{option === "NOVEDADES" ? (
						<button
							className="buttonTime13"
							onClick={() => changeButton("NOVEDADES")}
						>
							Novedades
						</button>
					) : (
						<button
							className="buttonTime12"
							onClick={() => changeButton("NOVEDADES")}
						>
							Novedades
						</button>
					)}
				</div>
			</div>
			<div className="containerTime03">
				{option === null && <ListEntitiesInitial />}
				{/* {option === "Empleados " && <EmployessApp area={localUser.area} />} */}
				{option === "EPS" && <EntitiesApp type={"EPS"} />}
				{option === "ARL" && <EntitiesApp type={"ARL"} />}
				{option === "PENSIONES" && <EntitiesApp type={"PENSIONES"} />}
				{option === "CESANTIAS" && <EntitiesApp type={"CESANTIAS"} />}
				{option === "CAJA" && <EntitiesApp type={"CAJA"} />}
				{option === "CARGOS" && <EntitiesApp type={"CARGOS"} />}
				{option === "TIPO ID" && <EntitiesApp type={"TIPO ID"} />}
				{option === "TIPO CONTRATO" && <EntitiesApp type={"TIPO CONTRATO"} />}
				{option === "NOVEDADES" && <EntitiesApp type={"NOVEDADES"} />}
			</div>
		</div>
	);
};
