import React from "react";
import { Link } from "react-router-dom";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw2stages() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>
			{ModuleKeyProcess === "si" && (
				<div className="div01Navbar">
					<Link to="/process-list" className="linkNavbar">
						Procesos
					</Link>
				</div>
			)}
			{ModuleKeyProcess === "si" && (
				<div className="div01Navbar">
					<Link to="/stages-list" className="linkNavbar">
						Etapas
					</Link>
				</div>
			)}
			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{ModuleKeyProcess === "si" && (
				<div className="div01Navbar">
					<Link to="/indicators" className="linkNavbar">
						Indicadores
					</Link>
				</div>
			)}
			{ModuleKeyProcess === "si" && (
				<div className="div01Navbar">
					<Link to="/DataBase" className="linkNavbar">
						DataBase
					</Link>
				</div>
			)}

			<div className="div02Navbar">
				<Link to="/admin_user" className="linkNavbar">
					{/* {localUser.email} */}
					Usuario
				</Link>
			</div>
			<div className="divCloseNavbar">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
