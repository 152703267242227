import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	where,
} from "@firebase/firestore";
import { deleteDocumentById } from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Modal from "../../../generalComponents/Modal";

import "../../../styles/HumanResources.css";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { PermissionsForm } from "./permissionsForm";
import { PermisionsTable } from "./permisionsTable";

export const PermissionsApp = ({ employeeData }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [listEmployees, setListEmployees] = useState(null);
	const [dataBase2, setDataBase2] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showEmploye, setShowEmploye] = useState(false);
	const [listNovelties, setListNovelties] = useState(null);
	const [showSearchNovelties, setShowSearchNovelties] = useState(false);
	const [showNovelties, setShowNovelties] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	useEffect(() => {
		askPermisions();
	}, []);

	const askPermisions = () => {
		setLoading(true);
		const nameCollection = "noveltiesEmployees-" + company;
		const q = query(
			collection(db, nameCollection),
			where("noveltyName", "==", "Permiso"),
			where("employeeDocument", "==", employeeData.document)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const clickCrear = () => {
		openModal1();
	};
	return (
		<div>
			<article className="titulo9">Gestión de Permisos</article>
			<div>
				<button onClick={clickCrear} className="btn-create-document">
					Solicitar Permiso
				</button>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<PermissionsForm
						employeeData={employeeData}
						listEmployees={listEmployees}
						listNovelties={listNovelties}
						closeModal1={closeModal1}
						user={localUser.email}
						company={company}
						// dataBase2={dataBase2}
						// createDocument={createDocument}
						// updateDocument={updateDocument}
						documentToEdit={documentToEdit}
						setDocumentToEdit={setDocumentToEdit}
						// setError={setError}
						// isOpenModal1={isOpenModal1}
					/>
				</Modal>
				{dataBase && dataBase.length > 0 ? (
					<PermisionsTable
						data={dataBase}
						// setShowForm={setShowForm}
						// setDocumentToEdit={setDocumentToEdit}
						// setDocumentToDelete={setDocumentToDelete}
						// deleteDocument={deleteDocument}
						// setShowEmploye={setShowEmploye}
					/>
				) : (
					<article className="titulo3">No hay permisos para mostrar...</article>
				)}
			</div>
		</div>
	);
};
