import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addDatakey1,
	addDataKey2,
	askDataKeyValue,
	askDataKeyValue2Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import { ProcessOrdersForm } from "./processOrdersForm";
import { ProcessOrdersTable } from "./processOrdersTable";

export const ProcessOrderApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado de Ordenes de Producción
	const [productionOrderToEdit, setProductionOrderToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const nameCollection = "productionOrders-" + company;

	const askProductionOrders = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			where("status", "==", "abierto"),
			orderBy("creationDate", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askProductionOrders();
	}, []);

	const clickCrear = () => {
		setProductionOrderToEdit(null);
		openModal1();
	};

	async function createProductionOrder(data) {
		const date = new Date();
		const actualDate = date.toLocaleDateString();
		data.creationDate = actualDate;
		setLoading(true);
		const response = await addDatakey1(
			nameCollection,
			data,
			"productionOrder",
			data.productionOrder
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askProductionOrders();
		setLoading(false);
	}

	async function updateProductionOrder(data) {
		setLoading(true);
		// console.log("data a modificar:", data);
		const docId = data.id;
		const changes = data;
		const key2 = "productionOrder";
		const value2 = data.productionOrder;
		// console.log("value2", value2);
		const response = await askDataKeyValue(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Orden ya existe ya existe");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		// await askAccounts();
		setLoading(false);
	}

	async function deleteProductionOrder(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar esta Orden de Producción ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			<div>
				{/* <NavbarBpw3 /> */}
				<article>
					{/* <p>Aquí se gestionan listas de clientes</p> */}
					<h3 className="titleProcess">ORDENES DE PRODUCCIÓN ({company})</h3>
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<ProcessOrdersForm
							company={company}
							createProductionOrder={createProductionOrder}
							updateProductionOrder={updateProductionOrder}
							productionOrderToEdit={productionOrderToEdit}
							setProductionOrderToEdit={setProductionOrderToEdit}
							closeModal={closeModal1}
						/>
					</Modal>
					{loading ? (
						<Loader />
					) : (
						<div>
							{error && <Message mensaje={error} bgColor="#dc3545" />}
							{dataBase ? (
								dataBase.length >= 0 ? (
									<div>
										{/* <button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nueva Orden de Producción
											</button> */}
										<ProcessOrdersTable
											data={dataBase}
											setProductionOrderToEdit={setProductionOrderToEdit}
											deleteProductionOrder={deleteProductionOrder}
											openModal={openModal1}
										/>
									</div>
								) : (
									<h3 className="alertEmpty">
										No hay Ordenes de Producción para mostrar
									</h3>
								)
							) : (
								<h3 className="alertEmpty">Buscando...</h3>
							)}
						</div>
					)}
				</article>
			</div>
		</div>
	);
};
