import { initializeApp } from "@firebase/app";
import "../../../components/styles/Production.css";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import { Redirect, useParams } from "react-router";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addDataKey2,
	askDataKeyValue2Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
// import { ProductionOrderViewForm } from "./productionOrderViewForm";
// import { ProductionOrderViewTable } from "./productionOrderViewTable";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import { ProcessOrderViewTable } from "./processOrderViewTable";
import RegProcessOrderApp from "./regProcessOrder/regProcessOrderApp";
import { ProductionDocumentsApp } from "../inventories/productionDocuments/productionDocumentsApp";

export const ProcessOrderViewApp = () => {
	let { productionOrder } = useParams();
	console.log("productionOrder:", productionOrder);

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado del productos en la Orden de Producción
	const [productionProductToEdit, setProductionProductToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [dataHeader, setDataHeader] = useState(null);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const nameCollection2 = "productionOrders-" + company;
	const nameCollection = "productionProduct-" + company;

	const askDataHeader = () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection2),
			where("productionOrder", "==", productionOrder)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// setDataHeader(dataList[0]);
				setLoading(false);
			},
			(error) => {
				setDataHeader(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProductionProduct = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			where("productionOrder", "==", productionOrder)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askDataHeader();
		askProductionProduct();
	}, []);

	const clickCrear = () => {
		setProductionProductToEdit(null);
		openModal1();
	};

	async function crateProductionProduct(data) {
		setLoading(true);
		const response = await addDataKey2(
			nameCollection,
			data,
			"productionOrder",
			productionOrder,
			"refProductionProduct",
			data.refProductionProduct
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askProductionProduct();
		setLoading(false);
	}

	async function updateProductionProduct(data) {
		setLoading(true);
		const docId = data.id;
		const changes = data;
		const key2 = "refProductionProduct";
		const value2 = data.refProductionProduct;
		const key3 = "productionOrder";
		const value3 = data.productionOrder;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError(
						"Esta Producto ya está en la lista, si desea modifique cantidades a producir..."
					);
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		setLoading(false);
	}

	async function deleteProductionProduct(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Está seguro de eliminar este producto de la Orden de Producción ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			{localUser.type === "3" ? (
				<div>
					<NavbarBpw3 />

					{dataHeader && (
						<div className="headerPO">
							<h4>Orden de Producción: {productionOrder} </h4>
							<label>--- Cliente: {dataHeader.client} -</label>
							<label>--- Fecha: {dataHeader.date1} -</label>
							<label>--- Orden de Compra: {dataHeader.purshaseOrder} -</label>
							<label>--- Status: {dataHeader.status} -</label>
						</div>
					)}

					<article>
						{/* <p>Aquí se gestionan listas de Productos de la Orden de Producción </p> */}
						{/* <h3 className="titleProcess">PRODUCTOS SOLICITADOS ({company})</h3> */}
						{/* <Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<ProductionOrderViewForm
								company={company}
								crateProductionProduct={crateProductionProduct}
								updateProductionProduct={updateProductionProduct}
								productionProductToEdit={productionProductToEdit}
								setProductionProductToEdit={setProductionProductToEdit}
								closeModal={closeModal1}
								productionOrder={productionOrder}
							/>
						</Modal> */}
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									dataBase.length >= 0 ? (
										<div>
											{/* <button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nuevo Item de Inventario
											</button> */}
											<ProcessOrderViewTable
												data={dataBase}
												setProductionProductToEdit={setProductionProductToEdit}
												deleteProductionProduct={deleteProductionProduct}
												openModal={openModal1}
											/>
											<ProductionDocumentsApp
												productionOrder={productionOrder}
											/>

											<RegProcessOrderApp
												productionOrder={productionOrder}
												productsList={dataBase}
											/>
										</div>
									) : (
										<h3 className="alertEmpty">
											No hay Productos para mostrar
										</h3>
									)
								) : (
									<h3 className="alertEmpty">Buscando...</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
