import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	collection,
	getFirestore,
	getDocs,
	updateDoc,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import {
	askDataKeyValue,
	updateCompliance,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export default function FormResponseAssignments({
	closeModal1,
	activityToEdit,
	setActivityToEdit,
	company,
}) {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	// const initialForm = {
	// 	response: "",
	// 	comment: "",
	// };
	const responseType = activityToEdit.responseType;
	const initialForm = activityToEdit;
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [closedAnswers, setClosedAnswers] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askClosedAnswers = async () => {
		setLoading(true);
		const nameCollection = "closedAnswers";
		const key = "responseType";
		const value = responseType; //hacer referencia a activityToEdit.responseType
		const response = await askDataKeyValue(nameCollection, key, value);

		if (response.statusResponse === true) {
			setClosedAnswers(response.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (responseType !== "Abierta") {
			askClosedAnswers();
		}
		setForm(activityToEdit);
	}, []);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (form.response === "" || form.response === "Seleccione una Respuesta") {
			alert("Debe dar una Respuesta...");
			return;
		}
		setLoading(true);
		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		const actualHour = fechita.toLocaleTimeString();

		form.date = actualDate;
		form.hour = actualHour;
		form.userChanges = localUser.email;
		const nameCollection = "activitiesRegister-" + company;
		if (form.id === null) {
			const q = query(
				collection(db, nameCollection),
				where("activity", "==", form.activity),
				where("stage", "==", form.stage),
				where("processNumber", "==", form.processNumber)
			);
			await getDocs(q).then((querySnapshot) => {
				form.id = querySnapshot.docs[0].id;
				console.log("res: ", querySnapshot.docs[0].id);
			});
		}
		const changes = form;
		console.log("va a actualizar con form: ", form);

		await updateDocumentById(nameCollection, form.id, changes);
		console.log(">>>< va a actualizar el cumplimiento....");
		await updateCompliance(changes, company);
		setActivityToEdit(null);
		handleReset();
		setLoading(false);
		setLoading(false);
		closeModal1();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		// setActivityToEdit(null);
	};

	const handleClose = (e) => {
		setActivityToEdit(null);
		closeModal1();
	};

	if (activityToEdit) {
		return (
			<div>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					<p className="title2-account">Actividad: {activityToEdit.activity}</p>
					<p className="title2-account">
						Descripción: {activityToEdit.descriptionActivity}
					</p>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo1">Respuesta:</label>
							{closedAnswers ? (
								<div>
									<select
										name="response"
										onChange={handleChange}
										value={form.response}
										className="input0"
									>
										<option defaultValue>Seleccione una Respuesta</option>
										{closedAnswers.map((linea) => (
											<option
												key={linea.responseOption}
												value={linea.responseOption}
											>
												{linea.responseOption}
											</option>
										))}
									</select>
								</div>
							) : (
								<div>
									<input
										type="text"
										name="response"
										placeholder="Respuesta"
										onChange={handleChange}
										value={form.response}
										className="input0"
									/>
								</div>
							)}
							<input
								type="text"
								name="comment"
								placeholder="Comentario"
								onChange={handleChange}
								value={form.comment}
								className="input0"
							/>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		);
	} else {
		return (
			<div>
				<p>No hay información</p>
			</div>
		);
	}
}
