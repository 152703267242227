import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	where,
	getDocs,
	query,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Loader from "../../generalComponents/Loader";
import Select from "react-select";
import Message from "../../generalComponents/Message";
import { NoveltiesTable } from "./noveltiesTable";

export const SearchNoveltiesApp = ({
	setShowSearchNovelties,
	listEmployees,
	listNovelties,
	company,
	setDocumentToEdit,
	openModal1,
	setShowNovelties,
	deleteDocument,
}) => {
	const initialForm = {
		id: null,
		employeeDocument: "",
		employeeName: "",
		noveltyName: "",
		dateCreation: "",
		dateInitial: "",
		dateFinal: "",
		userCreation: "",
		userUpdate: "",
		dateUpdate: "",
		userAuthorizer: "",
		evidenceFile: "",
		comment: "",
		// reason: "",
		// commentBoss:"",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectEmployees, setSelectEmployees] = useState(null);
	const [dataBase, setDataBase] = useState(null);

	useEffect(() => {
		convertEmployees();
	}, []);

	const convertEmployees = () => {
		let selectUser = [];
		listEmployees.map((item) => {
			const lineList = {
				value: item.document,
				label: item.document + " - " + item.name,
				employe: item,
			};
			selectUser.push(lineList);
		});
		setSelectEmployees(selectUser);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const clickReturn = () => {
		setShowSearchNovelties(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const nameCollection = "noveltiesEmployees-" + company;
		let q = query(
			collection(db, nameCollection),
			where("dateInitial", ">=", form.dateInitial),
			where("dateInitial", "<=", form.dateFinal)
		);
		if (form.noveltyName !== "") {
			if (form.employeeDocument !== "") {
				console.log("van los dos");
				q = query(
					q,
					where("employeeDocument", "==", form.employeeDocument),
					where("noveltyName", "==", form.noveltyName)
				);
			} else {
				console.log(" siiii cambia q, novlety Name:", form.noveltyName);
				q = query(q, where("noveltyName", "==", form.noveltyName));
			}
		} else {
			if (form.employeeDocument !== "") {
				console.log("form.employeeDocument:", form.employeeDocument);
				q = query(q, where("employeeDocument", "==", form.employeeDocument));
			}
		}
		await getDocs(q)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				console.log("encontró: ", dataList);
				setDataBase(dataList);
			})
			.catch((error) => {
				console.log("hay error:", error);
			});
	};

	const handleSelectList = async (selectedOption, { name }) => {
		const value = selectedOption.value;

		setForm({
			...form,
			[name]: value,
		});
	};
	const handleReset = () => {
		setForm(initialForm);
	};
	const handleSelectEmploye = async (selectedOption) => {
		const employe = selectedOption.employe;
		setForm({
			...form,
			employeeDocument: employe.document,
			employeeName: employe.name,
		});
	};

	return (
		<div>
			<button onClick={clickReturn} className="btn-create-document">
				Regresar
			</button>

			<form onSubmit={handleSubmit} className="formRH">
				<article className="titulo8">
					Buscador de Novedades: Seleccionar Fecha Rango Inicio de la novedad ?
				</article>
				{loading ? (
					<Loader />
				) : (
					<div>
						<div className="containerFormRH">
							<label className="humanLabel0">Fecha Inicial *</label>
							<input
								type="date"
								name="dateInitial"
								placeholder="Fecha Inicial"
								onChange={handleChange}
								value={form.dateInitial}
								className="humanInput0"
							/>
							<label className="humanLabel0">Fecha Final *</label>
							<input
								type="date"
								name="dateFinal"
								placeholder="Fecha Final"
								onChange={handleChange}
								value={form.dateFinal}
								className="humanInput0"
							/>
							<label className="humanLabel0">Empleado </label>
							<Select
								className="humanSelect0"
								options={selectEmployees}
								value={
									form.employeeDocument
										? {
												label:
													form.employeeDocument + " - " + form.employeeName,
												value: form.employeeDocument,
										  }
										: null
								}
								onChange={handleSelectEmploye}
								placeholder="Empleado ?"
							/>

							<label className="humanLabel0">Tipo de Novedad </label>
							<Select
								className="humanSelect0"
								options={listNovelties}
								name="noveltyName"
								value={
									form.noveltyName
										? { label: form.noveltyName, value: form.noveltyName }
										: null
								}
								onChange={handleSelectList}
								placeholder="Tipo de Novedad ?"
							/>
						</div>

						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{form.dateInitial && form.dateFinal ? (
							<input
								type="submit"
								value="Buscar"
								className="btn-send-reg-asign"
							/>
						) : (
							<input
								type="reset"
								value="- - -"
								className="btn-clean-reg-asign"
							/>
						)}

						<input
							type="reset"
							value="Limpiar"
							onClick={handleReset}
							className="btn-clean-reg-asign"
						/>

						{/* <input
							type="reset"
							value="Cerrar"
							// onClick={closeForm}
							className="btn-close-reg-asign"
						/> */}
					</div>
				)}
			</form>
			{dataBase && dataBase.length ? (
				<NoveltiesTable
					documents={dataBase}
					setDocumentToEdit={setDocumentToEdit}
					// setDocumentToDelete={setDocumentToDelete}
					deleteDocument={deleteDocument}
					openModal1={openModal1}
					setShowNovelties={setShowNovelties}
					// openModal2={openModal2}
				/>
			) : (
				<article className="titulo2">No hay Novedades...</article>
			)}
		</div>
	);
};
