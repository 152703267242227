import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	where,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import { PermissionsApp } from "../../humanResources/novelties/permissions/permissionsApp";
import { ChangePassword } from "../usersPaswords/ChangePassword";
import { UsersFunctionsInitial } from "./usersFunctionsInitial";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { AuthorizationsApp } from "../../humanResources/novelties/authorizations/authorizationsApp";
import { UserPersonalData } from "./userPersonalData";

export const UsersFunctionsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [dataBase, setDataBase] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [option, setOption] = useState(null);
	const [permisionsPendient, setPermisionsPendient] = useState(null);

	useEffect(() => {
		askEmployee();
		askAutorizerPermisions();
	}, []);

	const askEmployee = () => {
		setLoading(true);
		const q = query(
			collection(db, "employees-" + company),
			where("email", "==", localUser.email)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList[0]);

				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askAutorizerPermisions = () => {
		setLoading(true);
		const nameCollection = "noveltiesEmployees-" + company;
		const q = query(
			collection(db, nameCollection),
			where("noveltyName", "==", "Permiso"),
			where("userAuthorizer", "==", localUser.email),
			where("aprovalStatus", "==", "Pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));

				setPermisionsPendient(dataList);
				setLoading(false);
			},
			(error) => {
				setPermisionsPendient(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "1" ? (
				<NavbarBpw />
			) : localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : localUser.type === "4" ? (
				<NavbarBpw4 />
			) : (
				<Redirect to="/intruso" />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div className="containerTime01">
							<div className="containerTime22">
								{dataBase && (
									<div>
										{option === "Datos Personales " ? (
											<button
												className="buttonTime23"
												onClick={() => changeButton("Datos Personales ")}
											>
												Datos Personales
											</button>
										) : (
											<button
												className="buttonTime22"
												onClick={() => changeButton("Datos Personales ")}
											>
												Datos Personales
											</button>
										)}
									</div>
								)}

								{dataBase && dataBase.inmediateBoss && (
									<div>
										{option === "Permisos " ? (
											<button
												className="buttonTime23"
												onClick={() => changeButton("Permisos ")}
											>
												Permisos
											</button>
										) : (
											<button
												className="buttonTime22"
												onClick={() => changeButton("Permisos ")}
											>
												Permisos
											</button>
										)}
									</div>
								)}

								{dataBase &&
									permisionsPendient &&
									permisionsPendient.length > 0 && (
										<div>
											{option === "Aurorizar " ? (
												<button
													className="buttonTime23R"
													onClick={() => changeButton("Aurorizar ")}
												>
													Aurorizar ( {permisionsPendient.length} )
												</button>
											) : (
												<button
													className="buttonTime22R"
													onClick={() => changeButton("Aurorizar ")}
												>
													Aurorizar ( {permisionsPendient.length} )
												</button>
											)}
										</div>
									)}

								<div>
									{option === "Contraseña" ? (
										<button
											className="buttonTime23"
											onClick={() => changeButton("Contraseña")}
										>
											Contraseña
										</button>
									) : (
										<button
											className="buttonTime22"
											onClick={() => changeButton("Contraseña")}
										>
											Contraseña
										</button>
									)}
								</div>
							</div>

							<div className="containerTime03">
								{option === null && <UsersFunctionsInitial />}
								{option === "Datos Personales " && (
									<UserPersonalData employeeData={dataBase} />
								)}
								{option === "Permisos " && (
									<PermissionsApp employeeData={dataBase} />
								)}
								{option === "Aurorizar " && (
									<AuthorizationsApp permisionsPendient={permisionsPendient} />
								)}

								{option === "Contraseña" && <ChangePassword />}
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
