import React, { useState } from "react";
import { Link } from "react-router-dom";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw4() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visibleProduction, setVisibleProduction] = useState(false);
	const [visibleInventroy, setVisibleInventroy] = useState(false);

	function openProduction() {
		setVisibleProduction(true);
	}

	function closeProduction() {
		setVisibleProduction(false);
	}

	function openInventory() {
		setVisibleInventroy(true);
	}

	function closeInventrory() {
		setVisibleInventroy(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>
			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeProduction}>
					<div className="linkNavbar" onMouseEnter={openProduction}>
						Producción
					</div>
					{visibleProduction === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/worker-company/assignments" className="linkNavbar">
									Etapas Asignadas
								</Link>
							</div>

							<div className="div01Navbar01">
								<Link to="/particular-inventory" className="linkNavbar">
									Inventarios
								</Link>
							</div>
						</div>
					)}
				</div>
			)}

			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar">
					<Link to="/students_training_main_company" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}
			<div className="div02Navbar">
				<Link to="/admin_user" className="linkNavbar">
					{/* {localUser.email} */}
					Usuario
				</Link>
			</div>
			<div className="divCloseNavbar">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
