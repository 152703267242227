import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
// import "../../styles/TimeManagement.css";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import Message from "../../generalComponents/Message";
import Loader from "../../generalComponents/Loader";
import { GeneralAreasManagementInitial } from "../../generalAreas/generalAreasManagement/generalAreasManagementInitial";
import CrudRegProcessApp from "../../processes/regProcess/CrudRegProcessApp";
import CrudRegAssignmentsApp from "../../processes/regAssignments/CrudRegAssignmentsApp";
import EmployessApp from "../employees/employessApp";
import { ListEntitiesApp } from "../ListEntities/listEntitiesApp";
import { NoveltiesApp } from "../novelties/noveltiesApp";

export const HumanManagementApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [permitedClock, setPermitedClock] = useState(false);
	const [permitedReports, setPermitedReports] = useState(false);
	const [permitedDataBases, setPermitedDataBases] = useState(false);
	const [option, setOption] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : (
				localUser.type === "4" && <NavbarBpw4 />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div className="containerTime01">
							<div className="containerTime02">
								{localUser.type === "3" && (
									<div>
										<div>
											{option === "Empleados " ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Empleados ")}
												>
													Empleados
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Empleados ")}
												>
													Empleados
												</button>
											)}
										</div>
									</div>
								)}
								{localUser.type === "3" && (
									<div>
										<div>
											{option === "Novedades " ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Novedades ")}
												>
													Novedades
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Novedades ")}
												>
													Novedades
												</button>
											)}
										</div>
									</div>
								)}
								{localUser.type === "3" && (
									<div>
										<div>
											{option === "Supervisión " ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Supervisión ")}
												>
													Supervisión
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Supervisión ")}
												>
													Supervisión
												</button>
											)}
										</div>
									</div>
								)}

								<div>
									{localUser.type === "3" && (
										<div>
											{option === "Tareas Propias" ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Tareas Propias")}
												>
													Tareas Propias
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Tareas Propias")}
												>
													Tareas Propias
												</button>
											)}
										</div>
									)}
								</div>
								<div>
									{localUser.type === "3" && (
										<div>
											{option === "Listados" ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Listados")}
												>
													Listados
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Listados")}
												>
													Listados
												</button>
											)}
										</div>
									)}
								</div>
							</div>

							<div className="containerTime03">
								{option === null && (
									<GeneralAreasManagementInitial area={localUser.area} />
								)}
								{option === "Empleados " && (
									<EmployessApp area={localUser.area} />
								)}
								{option === "Novedades " && <NoveltiesApp />}
								{option === "Supervisión " && (
									<CrudRegProcessApp area={localUser.area} />
								)}
								{option === "Tareas Propias" && (
									<CrudRegAssignmentsApp area={localUser.area} />
								)}
								{option === "Listados" && <ListEntitiesApp />}
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
