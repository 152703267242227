import React, { useEffect, useState } from "react";
import { RegProcessOrderTableRow } from "./regProcessOrderTableRow";

export const RegProcessOrderTable = ({ regProcess, irVerDetalle, company }) => {
	function sumarDias(fecha, dias) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}

	const [regProcessFilter, setRegProcessFilter] = useState(regProcess);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setRegProcessFilter(regProcess);
		}
		setRegProcessFilter(
			regProcess.filter(
				(item) =>
					item.process.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.status.toLowerCase().includes(e.target.value) ||
					item.refProductionProduct.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div>
				<div className="document-search">
					<h2 className="title-search">Buscar</h2>
					<input
						type="search"
						name=""
						id=""
						onChange={handleSearch}
						className="search-box"
					/>
				</div>

				<label className="titulo3">
					Lista de Procesos asociados a la Orden
				</label>
			</div>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos">Fecha</th>
						<th className="titulo-tabla-procesos">Hora</th>
						<th className="titulo-tabla-procesos"># Proceso</th>
						<th className="titulo-tabla-procesos">Nombre Proceso</th>
						<th className="titulo-tabla-procesos">Producto</th>
						<th className="titulo-tabla-procesos">Entregado / Cantidad</th>
						<th className="titulo-tabla-procesos">Estado</th>
						<th className="titulo-tabla-procesos">Cambio</th>
						<th className="titulo-tabla-procesos">Cumplimiento</th>
						<th className="titulo-tabla-procesos">Avance</th>

						<th className="titulo-tabla-procesos">Acción</th>
					</tr>
				</thead>
				<tbody>
					{regProcess.length > 0 ? (
						regProcessFilter.map((el) => (
							<RegProcessOrderTableRow
								key={el.id}
								el={el}
								irVerDetalle={irVerDetalle}
								company={company}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
