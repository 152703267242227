import React from "react";
import { Link } from "react-router-dom";

export const ProcessOrdersTableRow = ({
	el,
	setProductionOrderToEdit,
	openModal,
	deleteProductionOrder,
}) => {
	const editButton = (el) => {
		setProductionOrderToEdit(el);
		openModal();
	};
	const date1 = new Date(el.promiseDate.seconds * 1000).toLocaleDateString();

	const deleteButton = (el) => {
		deleteProductionOrder(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.productionOrder}</td>
			<td className="colTableDocuments1">{el.client}</td>

			<td className="colTableDocuments1">{el.date1}</td>
			<td className="colTableDocuments1">{date1}</td>

			<td className="colTableDocuments2">
				{/* <button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
				</button>

				<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button> */}
				{/* <button onClick={() => deleteButton(el)} className="btnViewDocuments">
					Ver
				</button> */}
				<Link to={"/production-process/order/" + el.productionOrder}>
					<button className="btnViewDocuments">Ingresar</button>
				</Link>
			</td>
		</tr>
	);
};
