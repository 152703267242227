import Modal from "../../generalComponents/Modal";
import React, { useState, useEffect } from "react";
import { useModal } from "../../generalComponents/hooks/useModal";
import DetalleRegProcesosFormAsign from "./DetalleRegProcesosFormAsign";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
	askDataKeyValue2,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const DetalleRegProcesosEtapa = ({
	assignment,
	localUser,
	setRefresh,
	company,
}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [activities, setActivities] = useState([]);
	const [visible, setVisible] = useState(false);
	const [refresh2, setRefresh2] = useState(false);
	const [responses, setResponses] = useState(0);
	const [questions, setQuestions] = useState(null);

	const askActivities = async () => {
		setLoading(true);
		// console.log("company:", company);
		// console.log("processNumber:", assignment.processNumber);
		const nameCollection = "activitiesRegister-" + company;
		const key = "processNumber";
		const value = assignment.processNumber;
		const key2 = "stage";
		const value2 = assignment.stage;
		const response = await askDataKeyValue2(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			setActivities(response.data);
			setQuestions(response.data.length);
			let answers = 0;
			response.data.map((line) => {
				if (line.response !== "") {
					answers = answers + 1;
				}
			});
			setResponses(answers);
			setLoading(false);
		} else {
			setActivities([]);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		// console.log("ASIGNACIÓN:", assignment);
		setRefresh(false);
		askActivities();
	}, [refresh2]);

	const updateUsuarioAsignado = async (data) => {
		setLoading(true);
		const collection = "assignments-" + company;
		const docId = data.id;
		const changes = data;
		const response = await updateDocumentById(collection, docId, changes);
		setLoading(false);
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const clickAsignar = () => {
		openModal1();
		setRefresh2(true);
	};

	const verTareas = () => {
		setVisible(true);
	};

	const ocultarTareas = () => {
		setVisible(false);
	};

	return (
		<div>
			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<DetalleRegProcesosFormAsign
					localUser={localUser}
					closeModal={closeModal1}
					assignment={assignment}
					updateUsuarioAsignado={updateUsuarioAsignado}
					setRefresh={setRefresh}
				/>
			</Modal>
			<div
				className={
					assignment.assignment === "" ? "tituloetapas1" : "tituloetapas2"
				}
			>
				<table>
					<tbody>
						<tr>
							<td className="etapa-col1">{assignment.stage}</td>
							<td className="etapa-col1">
								Cumplimiento: {Math.round(assignment.complianceStage * 100)}%
							</td>
							{assignment.assignment ? (
								<td className="etapa-col2">{assignment.assignment}</td>
							) : (
								<td className="etapa-col2">
									<p>------</p>
								</td>
							)}
							<td className="etapa-col3">
								{assignment.area === "Producción" ? (
									(localUser.area === "Gerencia" ||
										localUser.area === "Producción") && (
										<button onClick={clickAsignar} className="btnAsignar">
											{assignment.assignment === "" ||
											assignment.assignment === null
												? "Asignar"
												: "Reasignar"}
										</button>
									)
								) : (
									<button onClick={clickAsignar} className="btnAsignar">
										{assignment.assignment === "" ||
										assignment.assignment === null
											? "Asignar"
											: "Reasignar"}
									</button>
								)}
							</td>
							<td>
								{visible === true ? (
									<button onClick={ocultarTareas} className="btnVisible">
										<VisibilityOffIcon style={{ fontSize: 20 }} />
									</button>
								) : (
									<button onClick={verTareas} className="btnVisible">
										<VisibilityIcon style={{ fontSize: 20 }} />
									</button>
								)}
							</td>
							<td className="etapa-col3">
								{responses === questions ? (
									<div className="responseColorFull">
										<p>{Math.round((responses * 100) / questions)}%</p>
									</div>
								) : (
									<div className="responseColorEmpty">
										<p>{Math.round((responses * 100) / questions)}%</p>
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{visible === true && (
				<div className="bloque2">
					<table className="bloque2">
						<thead>
							<tr>
								<th className="th1">Actividad</th>
								<th className="th1">Descripcion</th>
								<th className="th1">Respuesta</th>
								<th className="th1">Comentario</th>
								<th className="th1">Modificó</th>
								<th className="th1">fecha</th>
								<th className="th1">hora</th>
							</tr>
						</thead>
						<tbody className="bloque2">
							{activities.map((activity) => (
								<tr
									className={activity.response ? "sirespuesta" : "norespuesta"}
									key={activity.id}
								>
									<td className="tarea-col1">{activity.activity}</td>
									<td className="tarea-col1">{activity.descriptionActivity}</td>
									<td className="tarea-col2">{activity.response}</td>
									<td className="tarea-col4">{activity.comment}</td>
									<td className="tarea-col4">{activity.userChanges}</td>
									<td className="tarea-col2">{activity.date}</td>
									<td className="tarea-col2">{activity.hour}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default DetalleRegProcesosEtapa;
