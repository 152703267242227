import React from "react";
import { DocNoveltiesApp } from "./docNovelties/docNoveltiesApp";

export const ShowNoveltiesApp = ({
	setShowNovelties,
	data,
	setDocumentToEdit,
	openModal1,
	documentToEdit,
}) => {
	const buttonAction1 = () => {
		setShowNovelties(false);
	};

	const clickEditar = () => {
		setDocumentToEdit(data);
		openModal1();
	};
	return (
		<div>
			<article className="titulo11">
				NOVEDAD{" "}
				<button onClick={() => buttonAction1()} className="btn-return">
					Buscar Novedades
				</button>
			</article>

			<div
			// className="ContainerHojaVida"
			>
				<div className="containerCvLeft">
					{console.log("data:", data)}
					<table className="tabla-procesos">
						<thead>
							<tr className="fila-tabla-procesos">
								<th className=""> </th>
								<th className=""> </th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="">Nombre:</td>
								<td className="">{data.employeeName}</td>
							</tr>

							<tr className="">
								<td className="">Número de Documento:</td>
								<td className="">{data.employeeDocument}</td>
							</tr>
							<tr className="">
								<td className="">Tipo de Novedad:</td>
								<td className="">{data.noveltyName}</td>
							</tr>
							{data.noveltyName === "Permiso" && (
								<div>
									<tr className="">
										<td className="">Motivo de Permiso:</td>
										<td className="">{data.reason}</td>
									</tr>
									<tr className="">
										<td className="">Status del Permiso:</td>
										<td className="">{data.aprovalStatus}</td>
									</tr>
									<tr className="">
										<td className="">Encargado de autorizar:</td>
										<td className="">{data.userAuthorizer}</td>
									</tr>
									<tr className="">
										<td className="">Comentario del jefe:</td>
										<td className="">{data.commentBoss}</td>
									</tr>
									<tr className="">
										<td className="">Fecha última actualización:</td>
										<td className="">{data.dateUpdate}</td>
									</tr>
								</div>
							)}

							{data.noveltyName === "Retiro" && (
								<div>
									<hr />
									<tr className="">
										<td className="">Fecha de retiro: {"  "}</td>
										<td className="">{data.dateOut}</td>
									</tr>
									<hr />
								</div>
							)}

							<tr className="">
								<td className="">Fecha de Creación:</td>
								<td className="">{data.dateCreation}</td>
							</tr>
							{!data.dateOut && (
								<tr className="">
									<td className="">Fecha Inicial:</td>
									<td className="">{data.dateInitial}</td>
								</tr>
							)}
							{!data.dateOut && (
								<tr className="">
									<td className="">Fecha Final:</td>
									<td className="">{data.dateFinal}</td>
								</tr>
							)}

							<tr className="">
								<td className="">Comentario:</td>
								<td className="">{data.comment}</td>
							</tr>

							{/* {Object.entries(data).map(([key, value]) => (
								<tr key={key}>
									<td>{key}</td>
									<td>{value}</td>
								</tr>
							))} */}
						</tbody>
					</table>
					<button onClick={clickEditar} className="btnEditEmployee">
						Editar datos...
					</button>
				</div>
				<div className="containerCvRigth">
					<DocNoveltiesApp
						documentToEdit={documentToEdit}
						setDocumentToEdit={setDocumentToEdit}
					/>
				</div>
			</div>
		</div>
	);
};
