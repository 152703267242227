import React from "react";
import { Redirect } from "react-router";
import NavbarBpw from "../generalComponents/NavbarBpw";

export const DataVersionApp = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	// const tuObjetoJSON = {
	// 	formatType: "XML",
	// 	alarmData:
	// 		'<?xml version="1.0" encoding="utf-8" ?>\n' +
	// 		'<EventNotificationAlert version="2.0" xmlns="http://www.isa                                                         pi.org/ver20/XMLSchema">\n' +
	// 		"\t<deviceSerial>J75110343</deviceSerial>\n" +
	// 		"\t<eventType>fielddetection</eventType>\n" +
	// 		"\t<eventDescription>fielddetection alarm</eventDescription>                                                         \n" +
	// 		"\t<triggerTime>2023-10-02T16:17:25</triggerTime>\n" +
	// 		"\t<channelID>1</channelID>\n" +
	// 		"\t<channelName>Camera 01</channelName>\n" +
	// 		"\t<DetectionRegionList>\n" +
	// 		"\t\t<DetectionRegionEntry>\n" +
	// 		"\t\t\t<regionID>1</regionID>\n" +
	// 		"\t\t\t<sensitivityLevel>60</sensitivityLevel>\n" +
	// 		"\t\t\t<detectionTarget>human</detectionTarget>\n" +
	// 		"\t\t\t<TargetRect>\n" +
	// 		"\t\t\t\t<X>0.364844</X>\n" +
	// 		"\t\t\t\t<Y>0.627778</Y>\n" +
	// 		"\t\t\t\t<width>0.0335938</width>\n" +
	// 		"\t\t\t\t<height>0.118056</height>\n" +
	// 		"\t\t\t</TargetRect>\n" +
	// 		"\t\t\t<RegionCoordinatesList>\n" +
	// 		"\t\t\t\t<RegionCoordinates>\n" +
	// 		"\t\t\t\t\t<positionX>297</positionX>\n" +
	// 		"\t\t\t\t\t<positionY>813</positionY>\n" +
	// 		"\t\t\t\t</RegionCoordinates>\n" +
	// 		"\t\t\t\t<RegionCoordinates>\n" +
	// 		"\t\t\t\t\t<positionX>316</positionX>\n" +
	// 		"\t\t\t\t\t<positionY>675</positionY>\n" +
	// 		"\t\t\t\t</RegionCoordinates>\n" +
	// 		"\t\t\t\t<RegionCoordinates>\n" +
	// 		"\t\t\t\t\t<positionX>462</positionX>\n" +
	// 		"\t\t\t\t\t<positionY>718</positionY>\n" +
	// 		"\t\t\t\t</RegionCoordinates>\n" +
	// 		"\t\t\t\t<RegionCoordinates>\n" +
	// 		"\t\t\t\t\t<positionX>463</positionX>\n" +
	// 		"\t\t\t\t\t<positionY>847</positionY>\n" +
	// 		"\t\t\t\t</RegionCoordinates>\n" +
	// 		"\t\t\t</RegionCoordinatesList>\n" +
	// 		"\t\t</DetectionRegionEntry>\n" +
	// 		"\t</DetectionRegionList>\n" +
	// 		"</EventNotificationAlert>\n" +
	// 		"\n",
	// 	deviceSerial: "J75110343",
	// };

	// // Obtenemos la cadena XML del campo "alarmData"
	// const xmlString = tuObjetoJSON.alarmData;

	// // Creamos un objeto DOMParser
	// const parser = new DOMParser();

	// // Analizamos la cadena XML y obtenemos el documento XML
	// const xmlDoc = parser.parseFromString(xmlString, "text/xml");
	// console.log("xml: ", xmlDoc);

	// // Ahora puedes acceder a los elementos y atributos XML como si fuera un documento XML
	// const deviceSerial = xmlDoc.querySelector("deviceSerial").textContent;
	// const eventType = xmlDoc.querySelector("eventType").textContent;
	// const triggerTime = xmlDoc.querySelector("triggerTime").textContent;
	// const channelName = xmlDoc.querySelector("channelName").textContent;

	// // Ejemplo de cómo obtener información de un elemento con atributos
	// const sensitivityLevel = xmlDoc.querySelector("sensitivityLevel").textContent;
	// const detectionTarget = xmlDoc.querySelector("detectionTarget").textContent;

	// console.log("deviceSerial:", deviceSerial);
	// console.log("eventType:", eventType);
	// console.log("triggerTime:", triggerTime);
	// console.log("channelName:", channelName);
	// console.log("sensitivityLevel:", sensitivityLevel);
	// console.log("detectionTarget:", detectionTarget);

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />

					<aritcle className="titulo9">
						Versión: ProductionV03 github Marzo 03 2024 (MIGRACIÓN BASE DATOS
						VERSIÓN 03 ARTISANO)
					</aritcle>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
