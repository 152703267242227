import React, { useState, useEffect } from "react";
import {
	askDataKeyValue,
	askDataKeyValueOrder,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import DetalleRegProcesosEtapa from "./DetalleRegProcesosEtapa";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";

const DetalleRegProcesos = ({ setVerDetalle, elToShow, setelToShow }) => {
	const [loading, setLoading] = useState(false);
	const [assignments, setAssignments] = useState([]);
	const [error, setError] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [productionProduct, setProductionProduct] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const regProceso = elToShow.processNumber;
	const proceso = elToShow.process;
	let totalRegProcess = elToShow;

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const askAssignments = async () => {
		setLoading(true);
		const nameCollection = "assignments-" + company;
		const key2 = "processNumber";
		const value2 = regProceso;
		const response = await askDataKeyValueOrder(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			setAssignments(response.data);
			setLoading(false);
		} else {
			setAssignments([]);
			setError(response.error);
			setLoading(false);
		}
	};

	const askProductionProduct = async () => {
		setLoading(true);
		const nameCollection = "productionProduct-" + company;
		const key = "productionOrder";
		const value = totalRegProcess.productionOrder;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			setProductionProduct(response.data[0]);
			setLoading(false);
		} else {
			setProductionProduct(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askAssignments();
		if (totalRegProcess.productionOrder) {
			askProductionProduct();
		}
		setRefresh(false);
	}, [refresh]);

	const openQuantity = () => {
		openModal1();
	};

	const goList = () => {
		setVerDetalle(false);
	};
	return (
		<div>
			{localUser.type === "1" && <NavbarBpw />}
			<article className="titleProcess">DETALLE PROCESO ({company})</article>
			<button onClick={goList} className="btn-crear-proceso">
				Volver al listado
			</button>
			{/* <p>Aquí va a verse el detalle del proceso seleccionado</p> */}
			{proceso && regProceso && (
				<h3 className="titulo3">
					Proceso: {proceso} ( {regProceso} )
				</h3>
			)}
			{totalRegProcess && totalRegProcess.refProductionProduct && (
				<div>
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}></Modal>

					<article
						className={
							totalRegProcess.quantity == totalRegProcess.finishProduct
								? "titulo12"
								: "titulo13"
						}
					>
						Producto: {productionProduct && productionProduct.description} ---
						Cantidad a Producir: {totalRegProcess.quantity} --- Cantidad
						Producida: {totalRegProcess.finishProduct}
					</article>
				</div>
			)}
			<hr />
			{loading === true && <Loader />}
			{assignments.map((assignment) => (
				<DetalleRegProcesosEtapa
					key={assignment.id}
					assignment={assignment}
					localUser={localUser}
					setRefresh={setRefresh}
					company={company}
				/>
			))}
		</div>
	);
};

export default DetalleRegProcesos;
