import React, { useEffect, useState } from "react";
import {
	askDataKeyValue,
	askDataKeyValue2,
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

const CrudRegProcesosTableRow = ({ el, irVerDetalle, company }) => {
	// console.log("****** que hay en el.********", el);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [questions, setQuestions] = useState(el.questions);
	const processRegister = el;

	const updateQuestions = async () => {
		// console.log("< < < < < va a ejecutar updateQuestions");
		if (
			el.questions === 0 ||
			!el.questions ||
			el.questions > el.responses ||
			el.questions < el.responses
		) {
			// console.log("++***+++*** ingresó a grabar questions");
			const resp = await askDataKeyValue2Simple(
				"activitiesRegister-" + company,
				"process",
				el.process,
				"processNumber",
				el.processNumber
			);
			const activitiesList = resp.data;
			const questions1 = activitiesList.length;
			processRegister.questions = questions1;
			// console.log("______ antes del update que no funcioona el:", el);
			// console.log(
			// 	"______ antes del update que no funcioona processRegister:",
			// 	processRegister
			// );
			try {
				await updateDocumentById(
					"processRegister-" + company,
					el.id,
					processRegister
				);
				setQuestions(questions1);
			} catch (error) {
				console.log("*** erorr: ", error);
			}
		}
	};
	useEffect(() => {
		updateQuestions();
	}, []);

	const changeStatus = async (newStatus) => {
		setLoading(true);
		const nameCollection = "assignments-" + company;
		const key2 = "processNumber";
		const value2 = el.processNumber;
		const response = await askDataKeyValue(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			const assignments = response.data;
			assignments.map((assignment) => {
				assignment.status = newStatus;
				const collection = "assignments-" + company;
				const docId = assignment.id;
				const changes = assignment;
				updateDocumentById(collection, docId, changes);
			});
			processRegister.status = newStatus;
			const collection = "processRegister-" + company;
			const docId = processRegister.id;
			const changes = processRegister;
			updateDocumentById(collection, docId, changes);
			setLoading(false);
		} else {
			setError(response.error);
			setLoading(false);
		}
	};

	const buttonAction = (el) => {
		// localStorage.setItem("regProceso", el.processNumber);
		// localStorage.setItem("proceso", el.process);
		// localStorage.setItem("totalRegProcess", JSON.stringify(el));
		irVerDetalle(el);
	};

	const buttonChangeStatus = async () => {
		let newStatus = "";
		if (el.status === "abierto") {
			newStatus = "cerrado";
		} else {
			newStatus = "abierto";
		}
		await changeStatus(newStatus);
	};

	if (loading === true) {
		return <Loader />;
	} else {
		return (
			<tr className="fila-tabla-cuenstas">
				<td className="col-tabla-regtarea1">{el.date1}</td>
				<td className="col-tabla-regtarea1">{el.hour1}</td>
				<td className="col-tabla-regtarea2">{el.processNumber}</td>
				<td className="col-tabla-regprocesos1">{el.process}</td>
				<td className="col-tabla-regprocesos2">{el.status}</td>
				<td className="col-tabla-regprocesos2">
					<div>
						{el.status === "abierto" ? (
							<button
								onClick={() => buttonChangeStatus()}
								className="btnCloseRegprocesos"
							>
								Cerrar
							</button>
						) : (
							<button
								onClick={() => buttonChangeStatus()}
								className="btnOpenRegprocesos"
							>
								Abrir
							</button>
						)}
					</div>
				</td>
				<td className="col-tabla-regprocesos2">
					{Math.round(el.complianceProcess * 100)}%
				</td>
				<td className="col-tabla-regprocesos2">
					{el.responses === el.questions ? (
						<div className="responseColorFull">
							<p>{Math.round((el.responses * 100) / el.questions)}%</p>
						</div>
					) : (
						<div className="responseColorEmpty">
							<p>{Math.round((el.responses * 100) / el.questions)}%</p>
						</div>
					)}
				</td>
				<td className="col-tabla-regprocesos2">
					<button
						onClick={() => buttonAction(el)}
						className="btn-ver-regprocesos"
					>
						Ver
					</button>

					{/* <button
						onClick={() => buttonChangeStatus()}
						className="btnOpenRegprocesos"
					>
						{el.status === "abierto" ? "Cerrar" : "Abrir"}
					</button> */}
				</td>
			</tr>
		);
	}
};

export default CrudRegProcesosTableRow;
