import React, { useEffect, useState } from "react";
import { NovletiesTableRow } from "./novletiesTableRow";

export const NoveltiesTable = ({
	documents,
	setDocumentToEdit,
	// setDocumentToDelete,
	deleteDocument,
	openModal1,
	// openModal2,
	setShowNovelties,
}) => {
	const [data, setData] = useState(documents);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		setData(documents);
	}, [documents]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setData(documents);
		}
		setData(
			documents.filter(
				(item) =>
					item.employeeDocument.toLowerCase().includes(e.target.value) ||
					item.employeeName.toLowerCase().includes(e.target.value) ||
					item.noveltyName.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			{/* {console.log("data", data)} */}
			<h3 className="titulo1">Lista de Novedades de hoy:</h3>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments03">Creación</th>
						<th className="titleTableDocuments01">Nombre</th>
						<th className="titleTableDocuments03">Documento</th>
						<th className="titleTableDocuments01">Novedad</th>
						<th className="titleTableDocuments03">Fecha Inicial</th>
						<th className="titleTableDocuments03">Fecha Final</th>
						{localUser.type === "3" && (
							<th className="titleTableDocuments03">Acción</th>
						)}
						{localUser.type === "3" && (
							<th className="titleTableDocuments03">Borrar</th>
						)}
					</tr>
				</thead>
				<tbody>
					{data && data.length > 0 ? (
						data.map((el) => (
							<NovletiesTableRow
								key={el.id}
								el={el}
								setDocumentToEdit={setDocumentToEdit}
								// setDocumentToDelete={setDocumentToDelete}
								deleteDocument={deleteDocument}
								openModal1={openModal1}
								// openModal2={openModal2}
								setShowNovelties={setShowNovelties}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">No hay Noveades...</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
